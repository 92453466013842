import React, { Component } from 'react';
import LoginForm from './components/LoginForm';
import LogoutForm from './components/LogoutForm';

const verifyTokenCookie = async () => fetch('/.netlify/functions/verify');

class App extends Component {

  state = {
    loggedIn: null,
    loading: true,
  };

  async componentWillMount() {
    try {
      const response = await verifyTokenCookie();
      if (response.status === 200) {
        this.setState({ loggedIn: true });
      } else {
        this.setState({ loggedIn: false });
      }
    } catch (e) {
      console.error(e);
      this.setState({ loggedIn: false });
    } finally {
      this.setState({ loading: false });
    }
  }

  render() {
    const { loggedIn, loading } = this.state;

    return (
      <div className="w-full flex justify-center pt-32 z-10 bg-dots">
        <div className="mx-auto z-20">
          { !loading && loggedIn && <LogoutForm /> }
          { !loading && !loggedIn && <LoginForm /> }
        </div>
      </div>
    );
  }
}

export default App;

import React, { useState } from 'react';
import { GoogleLogin } from "react-google-login";
import background from '../background.jpg';

const getRedirectTarget = () => {
  const query = new URLSearchParams(window.location.search);
  const target = query.get('target');
  return target || window.location.origin; // fallback to current site
};

const authenticate = async tokenId => {
  const response = await fetch('/.netlify/functions/authenticate', {
    method: 'POST', body: JSON.stringify({ token: tokenId })
  });
  return response.json();
};
const setTokenCookie = (token, target) => window.location = `https://elbstack.com/.netlify/functions/set_login_cookie?token=${token}&target=${target}`;

const LoginForm = () => {
  const [error, setError] = useState(null);

  const onSuccess = async (result) => {
    try {
      const auth = await authenticate(result.tokenId);
      if (auth && auth.token) {
        const target = getRedirectTarget();
        setTokenCookie(auth.token, target);
      } else {
        setError('no token in response');
      }
    } catch (e){
      console.error(e);
      setError(e.message);
    }
  };

  const onFailure = (e) => {
    console.error(e);
    setError(e.error);
  };

  return (
    <div className="max-w-sm rounded overflow-hidden shadow-lg bg-white">
      <img className="w-full" src={background} alt="Sunset in the mountains" />
        <div className="px-6 py-4">
          <div className="font-bold text-xl mb-2">elbstack Login</div>
        </div>
        <div className="px-6 py-2">
          <GoogleLogin
            onSuccess={onSuccess}
            onFailure={onFailure}
            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
          />
          <p className={'text-base py-4 ' + (error ? 'text-red' : 'text-grey-darker')}>
            {error ? `Error: ${error}` : 'use your elbstack account to login'}
          </p>
        </div>
    </div>
  );
};

export default LoginForm;
import React from 'react';

const clearTokenCookie = () => window.location = `https://elbstack.com/.netlify/functions/set_login_cookie?token=null&target=${window.location.origin}`;

const LogoutForm = () => {
  return (
    <div className="max-w-sm rounded overflow-hidden shadow-lg bg-white p-4">
        <div className="px-6 py-2">
          <div className="font-bold text-xl mb-2">You are currently logged in</div>
        </div>
        <div className="px-6 py-2">
          <button className="bg-red rounded py-2 px-8 shadow-md text-white" onClick={clearTokenCookie}>Logout</button>
        </div>
    </div>
  );
};

export default LogoutForm;